<template>
  <v-snackbar
    v-model="snackbar"
    vertical
    right
    color="warning"
    :timeout="60 * 1000 * 4"
  >
    <v-row justify="space-between">
      <v-col cols="auto" class="py-0">
        <h4>Caixa de Entrada - Últimas Pendências</h4>
      </v-col>
      <v-col cols="auto" class="py-0">
        <v-btn icon color="white" class="mt-0 pt-0" @click="closeSnackbar()">
          <v-icon size="24">mdi-close</v-icon>
        </v-btn>
      </v-col>
    </v-row>
    <span v-for="(pendencia, idx) in pendencias" :key="idx">
      <v-spacer
        class="mt-1"
        v-if="
          pendencias[idx - 1] &&
            pendencias[idx - 1].company.sigla_oficial !=
              pendencia.company.sigla_oficial
        "
      />

      <b> {{ pendencia.company.sigla_oficial }} </b>: Pendente {{ getTextTipoPendencia(pendencia.tipo_pendencia) }} dos indicadores
      <b>
        {{ pendencia.base }} -
        {{ getTextTema(pendencia) }}
      </b>
      de
      <b> {{ getTextCompetencia(pendencia) | formatToMonth }} </b>
      <br />
    </span>
    <span class="mt-1">
      Verifique as demais pendências na <b>Caixa de Entrada</b>
    </span>
  </v-snackbar>
</template>

<script>
  import { mapState, mapActions } from 'vuex';
  import CaixaDeEntradaService from "@/services/CaixaDeEntradaService.js";

  export default {
    name: "SnackbarInbox",
    props: {},
    data: () => ({
      snackbar: false,
      pendencias: [],
    }),
    computed: {
      ...mapState("caixaDeEntrada", [
        "ultimasPendenciasReaded"
      ]),
    },
    mounted() {
      this.getUserPendencias();
    },
    methods: {
      ...mapActions("caixaDeEntrada", [
        "setUltimasPendenciasReaded"
      ]),
      getUserPendencias() {
        CaixaDeEntradaService.getUserPendencias()
          .then((res) => {
            this.pendencias = res
              .filter((val) => val.base != "INDGER" && val.done === false)
              .sort((a, b) => new Date(b.created_at) - new Date(a.created_at))
              .slice(0, 5)
              .sort((a, b) =>
                a.company.sigla_oficial.localeCompare(b.company.sigla_oficial)
              );

            if (this.pendencias.length > 0 && this.ultimasPendenciasReaded === false) {
              this.snackbar = true;
            }
          })
          .catch((err) => {
            this.snackbar = false;
            console.error(err);
          });
      },
      closeSnackbar() {
        this.snackbar = false;
        this.setUltimasPendenciasReaded(true);
      },
      getTextTipoPendencia(tipo_pendencia) {
        if (tipo_pendencia == "PendenciaAprovacaoIndicadores") {
          return "aprovação";
        }
        if (tipo_pendencia == "PendenciaImportacaoDados") {
          return "retificação";
        }
      },
      getTextTema(pendencia) {
        if (pendencia.tipo_pendencia == "PendenciaImportacaoDados") {
          return pendencia.tema?.category || pendencia.tema?.nome;
        }
        if (pendencia.tipo_pendencia == "PendenciaAprovacaoIndicadores") {
          return pendencia.category?.category || pendencia.category?.nome;
        }
      },
      getTextCompetencia(pendencia) {
        if (pendencia.tipo_pendencia == "PendenciaImportacaoDados") {
          return pendencia.data_month;
        }
        if (pendencia.tipo_pendencia == "PendenciaAprovacaoIndicadores") {
          return pendencia.indger_version.data_month;
        }
      },
    },
  };
</script>

<style></style>
